import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";


@Injectable({
    providedIn: 'root'
})
export class LoginService {
    router = inject(Router)

    isLoggedIn() {
        if (sessionStorage.getItem('token')) {
            return true;
        }
        else {
            this.router.navigate(['/login']);
            return false;
        }
    }

}

