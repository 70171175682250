import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EventService } from './shared/services/event.service';
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';
import { BaseComponent } from './base.component';
import { environment } from '../environments/environment';
import { ApiEndpoints } from './core/config/api-endpoints.config';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ProgressSpinnerModule, ToastModule, RippleModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends BaseComponent {
  title = 'Nova';
  url: any = environment.loginUrl;
  endPoints = ApiEndpoints.Auth;

  http = inject(HttpClient);
  constructor(serviceEvent: EventService, private route: ActivatedRoute) {
    super(serviceEvent);
  }

  ngOnInit() {
    const currentUrl = window.location.href;
    
    if (currentUrl.includes('token')) {
      const refreshToken = currentUrl.split('?token=')[1];
      sessionStorage.setItem('token', refreshToken);
      sessionStorage.setItem('refreshToken', refreshToken);
      if (refreshToken) {
        sessionStorage.setItem('backToNova', 'true');
        this.http.post<any>(this.url + this.endPoints.RefreshApi, { refreshToken }).subscribe(response => {
          sessionStorage.setItem('token', response?.accessToken);
          sessionStorage.setItem('refreshToken', response.refreshToken);
          // this.route.navigate(['/']);
          sessionStorage.setItem('userDetails', JSON.stringify(response.user));
          const newUrl = currentUrl.split('?')[0]; // Remove everything after '?'
          history.replaceState({}, document.title, newUrl);
        })
      } else {
        console.error('No token found in the URL');
      }
    }

  }
}
