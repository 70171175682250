<div class="logo"><img src="../../../../assets/images/logo.png"
        alt="Logo"></div>
<ul class="sideMenu">
    @for (item of navItems; track item.title) {
    <li>
        <div class="sidebarMenu" (click)="item.expand = !item.expand"
        [routerLink]="item.link" [routerLinkActive]="'active'" >
            <i class="{{item.icon}}"></i>
            <p >{{item.title}}</p>
            @if (item.children) {
                <span [class.expand]="item.expand" 
                class="pi pi-angle-down settingDropList"></span>}
        </div>
        <ul>
            @if (item.expand) { @for (subMenu of item.children; track
            subMenu.title) {
 
            <li [routerLink]="subMenu.link"
                [routerLinkActive]="'childActive' " ><span
                    class="{{subMenu.icon}}"></span>{{subMenu.title}}</li>
            } }
        </ul>
    </li>
    }
</ul>