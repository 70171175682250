import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations'
import { routes } from './app.routes';
import { UtilityService } from './shared/utility-services/utility.service';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { httpHandlerInterceptor } from './core/interceptors/http-handler.interceptor';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';


export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), ToastModule, 
    MessageService, provideAnimations(), 
    UtilityService, provideHttpClient(withInterceptors([httpHandlerInterceptor])),
  ]
};
