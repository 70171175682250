import { Component } from '@angular/core';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { HeaderComponent } from './components/header/header.component';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';

import { MenuModule } from 'primeng/menu';
@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [RouterModule,ButtonModule,
    SideNavComponent, HeaderComponent, MenuModule, AvatarModule],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {
  items: MenuItem[] | undefined;
  menu: boolean = false;
  pageName: string = "Campus";
  userDetails: any = [];
  isBackButton: any;
  constructor(private route: ActivatedRoute, private router: Router){
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    )
    .subscribe((data: any) => {
      this.pageName = data.title;
    });
    this.userDetails = sessionStorage?.getItem('userDetails');
    this.userDetails = JSON.parse(this.userDetails);
    this.items = [
      {separator: true},
      {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: () => this.logOut()
    }
    ];

   this.isBackButton = sessionStorage.getItem('backToNova');
  
  }

  navigateToNovaAssure(){
    const url = 'https://fleet.dev.novaassure.com/campus/';
    window.location.href = url;
  }

  logOut(){
    sessionStorage.clear();
    this.router.navigate(['/login'])
  }
}
