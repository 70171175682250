
<div class="mainWrapper">
    <app-side-nav></app-side-nav>
    <div class="contentWrapper">
        <div class="pageBanner">
            <div class="pageHead">  
                <h1>{{ pageName }} </h1>
                <div class="logoutMenu">
                    @if (isBackButton) {
                        <div (click)="navigateToNovaAssure()">
                          <span class="userName"><i class="pi pi-arrow-left"></i> Back To nova Assure </span>  
                        </div>
                    }
                    <p-menu #menu [model]="items" [popup]="true" styleClass="w-full md:w-15rem">
                        <ng-template pTemplate="start">
                          <h2>{{userDetails?.firstName }} {{userDetails?.lastName}}</h2>
                          <span class="roleName">Admin</span>
                        </ng-template>
                    </p-menu>
                    <i class="pi pi-user" (click)="menu.toggle($event)" > </i> 
                </div>
            </div>
        </div>
        <div class="contentSection">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
