import { bootstrapApplication, createApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { createCustomElement } from '@angular/elements';
import { CampusComponent } from './app/features/components/campus/campus.component';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ApplicationRef } from '@angular/core';

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));


  // platformBrowserDynamic().bootstrapModule(AppModule)
  // .catch(err => console.error(err));

  // (async () => {
  //   const app: ApplicationRef = await createApplication(appConfig);
  //   const MyComponent = createCustomElement(CampusComponent, { injector: app.injector });
  //   customElements.define('app-campus1', MyComponent);
  //   app.bootstrap(CampusComponent);
  //   // Define Web Components
  // })();

// (async () => {
//   const app = createApplication({providers:[CampusComponent, AppComponent]});
//   const PopupElement = createCustomElement(CampusComponent, {
//     injector: (await app).injector
//   });

//   customElements.define('app-campus1', PopupElement);

// })();